.Control {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
}

.Control .Name {
	width: 30%;
}

.Control .Amount {
	display: flex;
	justify-content: space-between;
	width: 40%;
	align-items: center;
}

.Control .Amount button {
	background-color: transparent;
	color: #fff;
    background-color: lightcoral;
	padding: 10px;
    border: none;
}

.Control .Amount button:hover {
	cursor: pointer;
}

.Control .Price {
	width: 30%;
	text-align: right;
}

/* .Control .Total {
    width: 20%;
    text-align: right;
} */

.Blue {
	color: blue;
}
