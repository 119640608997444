.Content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: calc(100vh - 70px);
    max-width: 1320px;
    margin: 70px auto 0;
}

@media (max-width: 1000px) {
    .Content {
        flex-direction: column;
        height: auto;
    }
}