.BurgerControls {
	display: flex;
	flex-direction: column;
	padding: 30px;
	width: 550px;
	margin: auto;
    min-height: 650px;
}

.ButtonWrapper {
	margin-top: 20px;
	display: flex;
	justify-content: space-around;
}

.ButtonWrapper button {
	color: #fff;
    background-color: lightcoral;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 10px;
}

.ButtonWrapper button:disabled {
	opacity: 0.4;
}

.ButtonWrapper button:hover {
	cursor: pointer;
}

.ingredient-types {
	display: flex;
    font-weight: bold;
    width: 100%;
}

.ingredient-types .type {
    padding: 5px 20px;
    flex: 2;
    text-align: center;
    color: lightcoral;
    border: 2px solid lightcoral;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ingredient-types .type:nth-of-type(3) {
    flex: 3;
}

.ingredient-types .type:hover {
	cursor: pointer;
}

.ingredient-types .type.active {
	background-color: lightcoral  !important;
    color: #fff;
}

.ingredients-of-type {
    padding: 30px 10px;
}

@media (max-width: 1000px) {
    .BurgerControls {
        width: 100%;
        padding: 15px;
        min-height: auto;
    }
}