.Wrapper {
	position: fixed;
	z-index: 11;
    width: 600px;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
    padding: 20px;
}

.Header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (max-width: 1000px) {
    .Wrapper {
        width: 90%;
    }
}