.Toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 70px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: lightcoral;
    z-index: 3;
    gap: 10px;
}

.Toolbar h1 {
    color: #fff;
}