.burger-wrapper {
    height: 650px;
    overflow-y: auto;
    width: 50%;
    display: flex;  
}

.Burger {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    margin-top: auto;
    display: block;
}

@media (max-width: 1000px) {
    .burger-wrapper {
        height: auto;
        width: 100%;
        padding-top: 20px;
    }
}